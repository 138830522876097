import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import Slider from "react-slick";
import Link from "lib/Link";
import withLocation from "lib/withLocation";
import { autobind } from "react-decoration";
import ReactHtmlParser from "react-html-parser";
import "static/vendor/slick/slick.css";
import "static/vendor/slick/slick-theme.css";

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class NewBooksBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  @autobind
  loadDefaultImg(e) {
    e.target.src = "/file/images/bookcover.jpg";
  }

  render() {
    let { t, appStore, readerStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      return null;
    }
    let { newBooksBlock } = indexdata;
    if (newBooksBlock === null || newBooksBlock === undefined) {
      return null;
    }
    let { domainList } = newBooksBlock;
    let { session } = readerStore;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let newBooksDomain = {
      domain: "default",
      mappingData: null,
      showType: 1,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          newBooksDomain = m;
        }
        return "";
      });
    }
    if (!newBooksDomain || !newBooksDomain.mappingData) {
      return null;
    }
    let slideOut = [];
    let slidesToShow = newBooksDomain.slidesToShow;
    let slidesToScroll = newBooksDomain.slidesToScroll;
    let moreUrl = "";
    newBooksDomain.mappingData.map((row) => {
      if (
        row.data &&
        row.condition === session.groupIDVal + "-" + session.useUnitGroupSN
      ) {
        moreUrl = row.moreUrl;
        row.data.map((row2) => {
          slideOut.push(
            <li>
              <Link href={row2.url} tabIndex="-1">
                <img
                  src={row2.imagePath}
                  alt={t("jumperrwd.common.book")}
                  onError={this.loadDefaultImg}
                />
                {ReactHtmlParser(row2.name)}
              </Link>
            </li>
          );
          return "";
        });
      }
      return "";
    });
    if (slidesToShow > slideOut.length) {
      slidesToShow = slideOut.length;
    }
    if (slidesToScroll > slideOut.length) {
      slidesToScroll = slideOut.length - 1;
    }
    let newBooksShowType = newBooksDomain.showType || 1;
    if (!newBooksShowType || newBooksShowType === 0) {
      newBooksShowType = 1;
    }
    if (newBooksShowType === 1) {
      return (
        <>
          <section className="section col-12 newbooks_block">
            <div className="container">
              <div className="col">
                <h2>{ReactHtmlParser(t(newBooksDomain.mainLangcode))}</h2>
                <Slider
                  className="newbooks"
                  dots={false}
                  infinite={true}
                  speed={500}
                  slidesToShow={slidesToShow}
                  slidesToScroll={slidesToScroll}
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: slidesToShow / 2,
                        slidesToScroll: slidesToScroll / 2,
                        dots: false,
                        infinite: true,
                      },
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: slidesToShow / 3,
                        slidesToScroll: slidesToScroll / 3,
                        dots: false,
                        infinite: true,
                      },
                    },
                  ]}>
                  {slideOut}
                </Slider>
                {moreUrl !== "" && (
                  <div className="more">
                    <Link href={moreUrl} className="btnstyle brownbg">
                      {t("jumperrwd.newBooksBlock.moreData")}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      );
    } else if (newBooksShowType === 2) {
      return (
        <>
          <section className="section col-12 newbooks_block">
            <div className="container">
              <div className="col">
                <h2>{ReactHtmlParser(t(newBooksDomain.mainLangcode))}</h2>
                {moreUrl !== "" && (
                  <div className="more">
                    <Link href={moreUrl} className="btnstyle brownbg">
                      {t("jumperrwd.newBooksBlock.moreData")}
                    </Link>
                  </div>
                )}
                <Slider
                  className="newbooks"
                  dots={false}
                  infinite={true}
                  speed={500}
                  slidesToShow={slidesToShow}
                  slidesToScroll={slidesToScroll}
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: slidesToShow / 2,
                        slidesToScroll: slidesToScroll / 2,
                        dots: false,
                        infinite: true,
                      },
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: slidesToShow / 3,
                        slidesToScroll: slidesToScroll / 3,
                        dots: false,
                        infinite: true,
                      },
                    },
                  ]}>
                  {slideOut}
                </Slider>
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return null;
    }
  }
}

NewBooksBlock.defaultProps = {};

NewBooksBlock.propTypes = {
  t: PropTypes.func,
};

export default NewBooksBlock;
