import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import Link from "lib/Link";
import withLocation from "lib/withLocation";
import { autobind } from "react-decoration";
import ReactHtmlParser from "react-html-parser";

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class ThemeBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  @autobind
  loadDefaultImg(e) {
    e.target.src = "/file/images/featured_pic02.jpg";
  }

  render() {
    let { t, appStore, readerStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      return null;
    }
    let { themeBlock } = indexdata;
    if (themeBlock === null || themeBlock === undefined) {
      return null;
    }
    let { domainList } = themeBlock;
    let { session } = readerStore;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let themeDomain = {
      domain: "default",
      mappingData: null,
      showType: 1,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          themeDomain = m;
        }
        return "";
      });
    }
    if (!themeDomain || !themeDomain.mappingData) {
      return null;
    }
    let moreUrl = "";
    let outList = [];
    themeDomain.mappingData.map((row) => {
      if (
        row.data &&
        row.condition === session.groupIDVal + "-" + session.useUnitGroupSN
      ) {
        moreUrl = row.moreUrl;
        row.data.map((row2) => {
          outList.push(
            <div className="featured_data">
              <Link href={row2.url}>
                <div className="datablock_top">
                  <div className="images">
                    <img
                      src={row2.imagePath}
                      onError={this.loadDefaultImg}
                      alt="主題圖片"
                    />
                  </div>
                </div>
                <div className="datablock_down">
                  <h3>{ReactHtmlParser(row2.name)}</h3>
                  <ul className="datalist">
                    <li>
                      {ReactHtmlParser(
                        this.props.t("jumperrwd.themeBlock.showTime", {
                          onlineDate: row2.onlineDate,
                          offlineDate: row2.offlineDate,
                        })
                      )}
                    </li>
                  </ul>
                </div>
              </Link>
            </div>
          );
          return "";
        });
      }
      return "";
    });
    let themeShowType = themeDomain.showType || 1;
    if (!themeShowType || themeShowType === 0) {
      themeShowType = 1;
    }
    if (themeShowType === 1) {
      return (
        <>
          <section className="section col-12 theme_eBook">
            <div className="container">
              <div className="col">
                <h2>{ReactHtmlParser(t(themeDomain.mainLangcode))}</h2>
                {outList}
                {moreUrl !== "" && (
                  <div className="more">
                    <Link href={moreUrl} className="btnstyle brownbg">
                      {t("jumperrwd.themeBlock.moreData")}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      );
    } else if (themeShowType === 2) {
      return (
        <>
          <section className="section col-12 theme_eBook">
            <div className="container">
              <div className="col">
                <h2>{ReactHtmlParser(t(themeDomain.mainLangcode))}</h2>
                {moreUrl !== "" && (
                  <div className="more">
                    <Link href={moreUrl} className="btnstyle brownbg">
                      {t("jumperrwd.themeBlock.moreData")}
                    </Link>
                  </div>
                )}
                {outList}
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return null;
    }
  }
}

ThemeBlock.defaultProps = {};

ThemeBlock.propTypes = {
  t: PropTypes.func,
};

export default ThemeBlock;
